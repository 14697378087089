body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #000;
  /* background-image: url('./images/img_bg_portrait@2x.png'); */
  background-size: cover;
  background-repeat: repeat;
}

/* .slider-container img {
  outline: none !important;
} */

/* 当屏幕宽度大于等于高度时，表示横向方向 */
@media screen and (orientation: landscape) {
  #root {
    background-image: url('./images/img_bg_landscape@2x.png');
  }
}

/* 当屏幕宽度小于高度时，表示纵向方向 */
@media screen and (orientation: portrait) {
  #root {
    background-image: url('./images/img_bg_portrait@2x.png');
  }
}